<template>
  <allOrder :listType="2" />
</template>

<script>
import allOrder from '../all-order'
export default {
  components: {
    allOrder
  }
}
</script>
